/* eslint typescript-sort-keys/string-enum: "error" */
export enum EntityType {
  ACCOUNT = 'ACCOUNT',
  ACQUISITION_PORTFOLIO = 'ACQUISITION_PORTFOLIO',
  ACQUISITION_PROPERTY = 'ACQUISITION_PROPERTY',
  ADDRESS = 'ADDRESS',
  AMENITY = 'AMENITY',
  APPLICATION = 'APPLICATION',
  BID = 'BID',
  BRAND = 'BRAND',
  CHARGE_TEMPLATE = 'CHARGE_TEMPLATE',
  COLLECTION = 'COLLECTION',
  COMMUNITY = 'COMMUNITY',
  DISPOSITION = 'DISPOSITION',
  DISPOSITION_OFFER = 'DISPOSITION_OFFER',
  DISPUTE = 'DISPUTE',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EVICTION = 'EVICTION',
  FILE = 'FILE',
  FIXED_ASSET = 'FIXED_ASSET',
  FLOOR_PLAN = 'FLOOR_PLAN',
  FORM = 'FORM',
  FORM_QUESTION = 'FORM_QUESTION',
  INVOICE = 'INVOICE',
  JOB = 'JOB',
  LEAD = 'LEAD',
  LEASE = 'LEASE',
  LEASE_DOCUMENT = 'LEASE_DOCUMENT',
  LEASING = 'LEASING',
  LISTING_PROCESS = 'LISTING_PROCESS',
  LOCK_CODE = 'LOCK_CODE',
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  OFFICE = 'OFFICE',
  ORGANIZATION = 'ORGANIZATION',
  PARTY = 'PARTY',
  PAYMENT = 'PAYMENT',
  PERSON = 'PERSON',
  PRICING = 'PRICING',
  PROMOTION = 'PROMOTION',
  PROPERTY = 'PROPERTY',
  PROPERTY_GROUP = 'PROPERTY_GROUP',
  PROPERTY_GROUPS_PERMISSION = 'PROPERTY_GROUPS_PERMISSIONS',
  PROPERTY_PROMOTION = 'PROPERTY_PROMOTION',
  PROPERTY_VALUATION = 'PROPERTY_VALUATION',
  RECURRING_WORK_ORDER = 'RECURRING_WORK_ORDER',
  RENEWAL = 'RENEWAL',
  REPORT = 'REPORT',
  RESIDENT = 'RESIDENT',
  ROLE = 'ROLE',
  SCOPE_ITEM = 'SCOPE_ITEM',
  SHOWING = 'SHOWING',
  SMART_HOME_SYSTEM = 'SMART_HOME_SYSTEM',
  SURVEY_ANSWER = 'SURVEY_ANSWER',
  TASK = 'TASK',
  TASK_TEMPLATE = 'TASK_TEMPLATE',
  TICKET = 'TICKET',
  TURN_REHAB = 'TURN_REHAB',
  UNDERWRITING_PROFILE = 'UNDERWRITING_PROFILE',
  UNIT = 'UNIT',
  UNIT_APPLICATION = 'UNIT_APPLICATION',
  UNIT_ONBOARDING = 'UNIT_ONBOARDING',
  USER = 'USER',
  UTILITY = 'UTILITY',
  VENDOR = 'VENDOR',
  VENDOR_INSURANCE_POLICY = 'VENDOR_INSURANCE_POLICY',
  VISIT = 'VISIT',
  WORK_ORDER = 'WORK_ORDER',
}

export type Entities = Partial<Record<EntityType, number[]>>;
