import Button from 'antd/es/button';
import Result from 'antd/es/result';
import type { FC } from 'react';
import { useNavigate } from 'react-router';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => navigate('/')}
          data-testid="not-found-page-back-button"
        >
          Back Home
        </Button>
      }
    />
  );
};

export default NotFoundPage;
