import Button from 'antd/es/button';
import Result from 'antd/es/result';
import type { FC } from 'react';
import { useNavigate } from 'react-router';

const ErrorPage: FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="500"
      title="Unexpected Error"
      subTitle="Sorry, the page you visited has thrown an unexpected error."
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorPage;
