import useStorageEvent from '@/hooks/useStorageEvent';
import { getAccessToken } from '@/utils/authority';
import { useState } from 'react';

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(getAccessToken());

  useStorageEvent('access_token', () => {
    setAccessToken(getAccessToken());
  });

  return { accessToken };
};
