import { UserService } from '@/services/user';
import HookBuilder from '../HookBuilder';

export const useUserPropertyGroups = HookBuilder.getByIdHook(UserService.findPropertyGroups, {
  errorMessage: 'There was an error fetching the property groups',
});

export const useUsers = HookBuilder.searchParamsHook(UserService.search, {
  errorMessage: 'There was an error while loading the users',
});

export const useUser = HookBuilder.getByIdHook(UserService.findById, {
  errorMessage: 'There was an error fetching the user',
});

export const useMe = HookBuilder.noParamsHook(UserService.me, {
  errorMessage: 'There was an error fetching the logged user information',
});

export const useCallCenterAgent = HookBuilder.getByIdHook(UserService.findCallCenterAgent, {
  errorMessage: 'There was an error fetching the call center agent',
});
