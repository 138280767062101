import type { Option } from '@/domain/base';
import type { Fetcher } from '@/utils/request';
import { useFetch } from '@/utils/request';
import { arrayToMap } from '@/utils/utils';
import { useMemo } from 'react';

type DefaultOptions = {
  errorMessage: string | undefined;
};

interface FetchHookOptions<T> {
  disabled?: boolean;
  errorMessage?: string;
  onSuccess?: (data: T) => void;
  revalidateOnMount?: boolean;
}

interface OptionsSettings {
  includeNoneOption?: boolean;
}

type ParseToMapConfig<Result extends any, MapKey, MapValue> = {
  getKey: (item: Result extends any[] ? Result[0] : Result) => MapKey;
  getValue?: (item: Result extends any[] ? Result[0] : Result) => MapValue;
};

type ParseToOptionsConfig<Result extends any, OptionValue> = {
  getLabel: (item: Result extends any[] ? Result[0] : Result) => string;
  getValue?: (item: Result extends any[] ? Result[0] : Result) => OptionValue;
  noneOption?: Option<OptionValue>;
  sort?: boolean;
};

type MapAndOptionsHookOptions<
  Result extends any,
  MapKey extends keyof any,
  MapValue,
  OptionValue,
> = {
  parseToMap?: ParseToMapConfig<Result, MapKey, MapValue>;
  parseToOptions?: ParseToOptionsConfig<Result, OptionValue>;
};

type SearchHookOptions<
  Result extends any[],
  MapKey extends keyof any,
  MapValue,
  OptionValue,
  Params extends object = object,
> = DefaultOptions &
  MapAndOptionsHookOptions<Result, MapKey, MapValue, OptionValue> & {
    disable?: (params: Partial<Params>) => boolean;
  };

type RegularHookOptions<Params extends any[]> = DefaultOptions & {
  disable?: (params: Params) => boolean;
};

type NoParamsHookOptions<
  Result extends any,
  MapKey extends keyof any,
  MapValue,
  OptionValue,
> = DefaultOptions & MapAndOptionsHookOptions<Result, MapKey, MapValue, OptionValue>;

const DEFAULT_EMPTY_PARAMS = {};

const useMapSettings = <
  Result extends any[],
  MapKey extends keyof any = keyof any,
  MapValue = Result[0],
>(
  data: Result | undefined,
  config: ParseToMapConfig<Result, MapKey, MapValue> | undefined,
) => {
  const { getKey: mapKey, getValue: mapValue } = config ?? {};
  return useMemo<Partial<Record<MapKey, MapValue>>>(() => {
    if (!mapKey || !data) {
      return {};
    }

    return arrayToMap(data, mapKey, mapValue);
  }, [data, mapKey, mapValue]);
};

const useOptionsSettings = <Result extends any[], OptionValue = Result>(
  data: Result | undefined,
  config: ParseToOptionsConfig<Result, OptionValue> | undefined,
  optionsSettings: OptionsSettings | undefined,
) => {
  const { getLabel, getValue, sort: optionsSort = true } = config || {};

  return useMemo<Option<OptionValue>[]>(() => {
    if (!getLabel || !getValue || !data) {
      return [];
    }

    const mappedOptions = data.map((item) => ({
      label: getLabel(item),
      value: getValue(item),
    }));

    if (optionsSort) {
      mappedOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    if (optionsSettings?.includeNoneOption && config?.noneOption) {
      mappedOptions.unshift(config.noneOption);
    }

    return mappedOptions;
  }, [data, getLabel, getValue, optionsSort, optionsSettings, config?.noneOption]);
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrand = HookBuilder.regularHook(BrandsService.getById, {
 *   errorMessage: 'There was an error fetching the brand',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brand } = useBrand([id]);
 * // Or:
 * const { data: brand } = useBrand([id], {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const regularHook = <
  Params extends any[],
  Result = any,
  Error = any,
  FetchParameters extends any[] = any[],
>(
  fetcher: Fetcher<Result, Error, FetchParameters, Params>,
  defaultOptions: RegularHookOptions<Params>,
) => {
  // If we didn't have optional parameters in hooks we could use something like this:
  // `type Args = [...Params, FetchHookOptions<Result>?];`
  // but since we cannot know if optional parameters are passed or not
  // (and same happens with `options`) we can't use this approach

  return (params: Params, fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS) => {
    const { disabled, onSuccess, ...options } = fetchOptions;
    const disabledByParams = useMemo(() => {
      if (!defaultOptions.disable) {
        return false;
      }

      return defaultOptions.disable(params);
    }, [params]);

    // eslint-disable-next-line no-restricted-syntax
    return useFetch(
      {
        ...options,
        ...(onSuccess ? { onSuccess } : {}), // SWR fails if we pass `undefined` here (QR-1424)
        fetcher: !disabled && !disabledByParams ? fetcher : undefined,
        errorMessage:
          'errorMessage' in options ? options.errorMessage : defaultOptions.errorMessage,
      },
      ...params,
    );
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that require only an ID as parameter
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrand = HookBuilder.getByIdHook(BrandsService.getById, {
 *   errorMessage: 'There was an error fetching the brand',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brand } = useBrand(id);
 * // Or:
 * const { data: brand } = useBrand(id, {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const getByIdHook = <Result = any, Error = any, FetchParameters extends any[] = any[]>(
  fetcher: Fetcher<Result, Error, FetchParameters, number[]>,
  defaultOptions: DefaultOptions,
) => {
  return (
    id: number | number[] | undefined,
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
  ) => {
    const { disabled, onSuccess, ...options } = fetchOptions;

    const idArray = Array.isArray(id) ? id : [id || 0];
    const idMissing = idArray.some((i) => !i);

    // eslint-disable-next-line no-restricted-syntax
    return useFetch(
      {
        ...options,
        ...(onSuccess ? { onSuccess } : {}), // SWR fails if we pass `undefined` here (QR-1424)
        fetcher: disabled || idMissing ? undefined : fetcher,
        errorMessage:
          'errorMessage' in options ? options.errorMessage : defaultOptions.errorMessage,
      },
      ...idArray,
    );
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that don't require any parameters
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/brands.ts` do:
 * const useBrands = HookBuilder.noParamsHook(BrandsService.search, {
 *   errorMessage: 'There was an error fetching the brands',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: brands } = useBrands();
 * // Or:
 * const { data: brands } = useBrands({
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const noParamsHook = <
  Result = any,
  Error = any,
  MapKey extends keyof any = keyof any,
  MapValue = any,
  OptionValue = any,
>(
  fetcher: Fetcher<Result, Error, any, []>,
  defaultOptions: NoParamsHookOptions<Result, MapKey, MapValue, OptionValue>,
) => {
  return (
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
    optionsSettings: OptionsSettings = DEFAULT_EMPTY_PARAMS,
  ) => {
    const { disabled, onSuccess, ...options } = fetchOptions;

    // eslint-disable-next-line no-restricted-syntax
    const result = useFetch({
      ...options,
      ...(onSuccess ? { onSuccess } : {}), // SWR fails if we pass `undefined` here (QR-1424)
      fetcher: !disabled ? fetcher : undefined,
      errorMessage: 'errorMessage' in options ? options.errorMessage : defaultOptions.errorMessage,
    });

    const arrayData = Array.isArray(result.data) ? result.data : undefined;

    // Build `map` property
    const map = useMapSettings(arrayData, defaultOptions.parseToMap);

    // Build `options` property
    const optionsArray = useOptionsSettings(
      arrayData,
      defaultOptions.parseToOptions,
      optionsSettings,
    );

    return {
      ...result,
      map,
      options: optionsArray,
    };
  };
};

/**
 * **This function must be used only when creating the hook that will be used in the components**
 *
 * Used for hooks that require search parameters
 *
 * @example
 * ```typescript
 * // In a file like `src/hooks/services/user.ts` do:
 * const useUsers = HookBuilder.searchParamsHook(UserService.search, {
 *   errorMessage: 'There was an error fetching the users',
 * });
 *
 * // Then in the component we use it like this:
 * const { data: users } = useUsers({ status });
 * // Or:
 * const { data: users } = useUsers({ status }, {
 *   // Custom options here...
 *   errorMessage: 'Custom error message',
 * });
 * ```
 */
const searchParamsHook = <
  Params extends object = object,
  Result extends any[] = any[],
  MapKey extends keyof any = keyof any,
  MapValue = Result[0],
  OptionValue = any,
  Error = any,
  FetchParameters extends any[] = any[],
>(
  fetcher: Fetcher<Result, Error, FetchParameters, [Partial<Params>]>,
  defaultOptions: SearchHookOptions<Result, MapKey, MapValue, OptionValue, Params>,
) => {
  return (
    params: Partial<Params> = DEFAULT_EMPTY_PARAMS,
    fetchOptions: FetchHookOptions<Result> = DEFAULT_EMPTY_PARAMS,
    optionsSettings: OptionsSettings = DEFAULT_EMPTY_PARAMS,
  ) => {
    const { disabled, onSuccess, ...options } = fetchOptions;
    const disabledByParams = useMemo(() => {
      if (!defaultOptions.disable) {
        return false;
      }

      return defaultOptions.disable(params);
    }, [params]);

    // eslint-disable-next-line no-restricted-syntax
    const result = useFetch(
      {
        ...options,
        ...(onSuccess ? { onSuccess } : {}), // SWR fails if we pass `undefined` to `onSuccess` here (QR-1424)
        fetcher: !disabled && !disabledByParams ? fetcher : undefined,
        errorMessage:
          'errorMessage' in options ? options.errorMessage : defaultOptions.errorMessage,
      },
      params,
    );

    // Build `map` property
    const map = useMapSettings(result.data, defaultOptions.parseToMap);

    // Build `options` property
    const optionsArray = useOptionsSettings(
      result.data,
      defaultOptions.parseToOptions,
      optionsSettings,
    );

    return { ...result, map, options: optionsArray };
  };
};

const HookBuilder = {
  regularHook,
  getByIdHook,
  noParamsHook,
  searchParamsHook,
};

export default HookBuilder;
