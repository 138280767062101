import { getHistory } from '@/history';
import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

export const Root: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      location.pathname === getHistory().location.pathname &&
      location.search === getHistory().location.search &&
      location.hash === getHistory().location.hash
    ) {
      return;
    }
    getHistory().replace(location.pathname + location.search + location.hash);
  }, [location.pathname, location.search, location.hash]);

  useLayoutEffect(() => {
    return getHistory().listen((change) => {
      if (
        location.pathname === change.location.pathname &&
        location.search === change.location.search &&
        location.hash === change.location.hash
      ) {
        return;
      }
      navigate(change.location.pathname + change.location.search + change.location.hash, {
        replace: true,
      });
    });
  }, [location.pathname, location.search, location.hash, navigate]);

  return <Outlet />;
};
