import type { Party } from '@/domain/person';
import type {
  CreateTicketRequest,
  NewContactFormType,
  Ticket,
  TicketBulkCreateBody,
  TicketBulkUpdateParams,
  TicketBulkWrapUpRequest,
  TicketReason,
  TicketReasonSearchParams,
  TicketResolveBody,
  TicketSearchParams,
  TicketSearchResult,
  TicketUpdateEntitiesRequest,
  TicketWrapUpBody,
  UpdateContactForm,
} from '@/domain/ticket';
import { api } from '@/utils/configuration';
import { getEvents } from '@/utils/eventSource';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const TicketService = {
  search: getAxiosFetcher<TicketSearchResult[], [TicketSearchParams?]>((params) => [
    api.TICKETS,
    { params },
  ]),

  create: (newTicket: CreateTicketRequest) => crudService.post<Ticket>(newTicket, api.TICKETS),

  update: (ticket: Partial<Ticket> & Required<Pick<Ticket, 'id'>>) =>
    crudService.put<Ticket>(ticket, `${api.TICKETS}/${ticket.id}`),

  patch: (ticketId: number, operations: Operation[]) =>
    crudService.patch<Ticket>(operations, `${api.TICKETS}/${ticketId}`),

  resolve: (ticketId: number, body: TicketResolveBody) =>
    crudService.put<void>(body, `${api.TICKETS}/${ticketId}/resolve`),

  claim: (ticketId: number) => crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/claim`),

  cancelTicket: (ticketId: number) =>
    crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/cancel`),

  cancelClaim: (ticketId: number) => crudService.delete<Ticket>(`${api.TICKETS}/${ticketId}/claim`),

  reset: (ticketId: number) =>
    crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/claim/reset`),

  markAsSpam: (ticketId: number) => crudService.post<Ticket>({}, `${api.TICKETS}/${ticketId}/spam`),

  managed: getAxiosFetcher<Ticket[], [number]>((ticketId) => [
    `${api.TICKETS}/${ticketId}/managed`,
  ]),

  wrapUp: (id: number, data: TicketWrapUpBody) =>
    crudService.post<Ticket>(data, `${api.TICKETS}/${id}/wrap-up`),

  followUp: (ticketId: number, followUpTicket: CreateTicketRequest) =>
    crudService.post<Ticket>(followUpTicket, `${api.TICKETS}/${ticketId}/follow-up`),

  createContact: (ticketId: number, newContact: NewContactFormType) =>
    crudService.post<Party>(newContact, `${api.TICKETS}/${ticketId}/contact`),

  updateContact: (ticketId: number, contactId: number, contact: UpdateContactForm) =>
    crudService.post<Party>(contact, `${api.TICKETS}/${ticketId}/contact/${contactId}`),

  getReasons: getAxiosFetcher<TicketReason[], [TicketReasonSearchParams?]>((params) => [
    api.TICKET_REASONS,
    { params },
  ]),

  bulkUpdate: (params: TicketBulkUpdateParams) => crudService.put<void>(params, api.TICKETS),

  bulkCreate: (body: TicketBulkCreateBody) =>
    crudService.post<Ticket[]>(body, `${api.TICKETS}/bulk`),

  getEvents: () => getEvents(`${api.TICKETS}/events`),

  updateEntities: (ticketId: number, request: TicketUpdateEntitiesRequest) =>
    crudService.put<void>(request, `${api.TICKETS}/${ticketId}/entities`),

  bulkWrapUp: (request: TicketBulkWrapUpRequest) =>
    crudService.put<void>(request, `${api.TICKETS}/bulk-wrap-up`),
};
