import { getValidAccessToken } from '@/utils/refreshToken';
import noop from 'lodash/noop';
import queryString from 'query-string';
import ReconnectingEventSource from 'reconnecting-eventsource';

type Listeners = {
  type: string;
  listener: (this: EventSource, event: MessageEvent) => any | EventListenerOrEventListenerObject;
  proxyListener: (
    this: EventSource,
    event: MessageEvent,
  ) => any | EventListenerOrEventListenerObject;
  options?: boolean | AddEventListenerOptions;
}[];

class PropifyEventSource implements EventSource {
  public readonly CONNECTING = 0;
  public readonly OPEN = 1;
  public readonly CLOSED = 2;
  private _readyState: 0 | 1 | 2;
  private _url: string;
  public readonly withCredentials: boolean = false;
  public onopen: ((this: EventSource, ev: Event) => any) | null = null;
  public onmessage: ((this: EventSource, ev: MessageEvent) => any) | null = null;
  public onerror: ((this: EventSource, ev: Event) => any) | null = null;
  private _eventSource: EventSource | null = null;
  private _oldEventSources: EventSource[] = [];

  private readonly listeners: Listeners = [];
  private readonly _onClose: () => void;

  constructor(url: string) {
    this._url = url;
    this._readyState = this.CONNECTING;
    this._connect(url);

    const handler = (ev: StorageEvent) => {
      if (ev.key === 'access_token') {
        this._connect(url);
      }
    };

    window.addEventListener('storage', handler);

    this._onClose = () => window.removeEventListener('storage', handler);
  }

  private async _connect(url: string) {
    if (!url.includes('accessToken')) {
      const accessToken = await getValidAccessToken();
      this._url = `${url}${url.includes('?') ? '&' : '?'}accessToken=${accessToken}`;
    }

    const oldEventSource = this._eventSource;
    if (oldEventSource) {
      oldEventSource.onopen = noop;
      this.listeners
        .filter((l) => l.type === 'open')
        .forEach((l) => {
          oldEventSource.removeEventListener(l.type, l.proxyListener, l.options);
        });
      this._oldEventSources.push(oldEventSource);
    }

    this._eventSource = new ReconnectingEventSource(this._url);
    this._eventSource.onopen = (e) => this._onopen(e);
    this._eventSource.onmessage = (e) => this._onmessage(e);
    this._eventSource.onerror = (e) => this._onerror(e);
    this.listeners.forEach((l) => {
      this._eventSource?.addEventListener(l.type, l.proxyListener, l.options);
    });
  }

  private _onopen(event: Event) {
    this._oldEventSources.forEach((e) => e.close());
    this._oldEventSources = [];

    if (this._readyState === this.OPEN) {
      return;
    }

    this._readyState = this.OPEN;
    this.onopen?.bind(this)(event);
  }

  private _onmessage(message: MessageEvent) {
    this.onmessage?.bind(this)(message);
  }

  private _onerror(error: Event) {
    this.onerror?.bind(this)(error);
  }

  get url(): string {
    return this._url;
  }

  get readyState(): number {
    return this._readyState;
  }

  close() {
    if (this._readyState === this.CLOSED) {
      return;
    }

    this._onClose();
    this._eventSource?.close();
    this._oldEventSources.forEach((es) => es.close());
    this._readyState = this.CLOSED;
  }

  dispatchEvent(event: Event): boolean {
    return this._eventSource?.dispatchEvent(event) ?? false;
  }

  addEventListener<K extends keyof EventSourceEventMap>(
    type: K,
    listener: (this: EventSource, ev: EventSourceEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: (this: EventSource, event: MessageEvent) => any | EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: (this: EventSource, event: MessageEvent) => any | EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void {
    const proxyListener: typeof listener =
      type === 'open'
        ? (...event) => {
            if (this._readyState === this.OPEN) {
              return;
            }
            listener.bind(this)(...event);
          }
        : listener;

    this.listeners.push({
      type,
      listener,
      proxyListener,
      options,
    });
    this._eventSource?.addEventListener(type, proxyListener, options);
    this._oldEventSources.forEach((es) => es.addEventListener(type, proxyListener, options));
  }

  removeEventListener<K extends keyof EventSourceEventMap>(
    type: K,
    listener: (this: EventSource, ev: EventSourceEventMap[K]) => any,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: (this: EventSource, event: MessageEvent) => any | EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener<K extends string>(
    type: K,
    listener: (this: EventSource, event: MessageEvent) => any | EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void {
    const index = this.listeners.findIndex(
      (l) => (l.type === type && l.listener === listener) || l.options === options,
    );
    if (index > -1) {
      const removed = this.listeners.splice(index, 1);
      removed.forEach((l) => {
        this._eventSource?.removeEventListener(l.type, l.proxyListener, l.options);
        this._oldEventSources.forEach((es) =>
          es.removeEventListener(l.type, l.proxyListener, l.options),
        );
      });
    }
  }
}

export const getEvents = (uri: string, params?: Record<string, any>) =>
  new PropifyEventSource(
    `/rest${uri}${uri.includes('?') ? '&' : '?'}${queryString.stringify(params ?? {})}`,
  );
