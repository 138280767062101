import useStorageEvent from '@/hooks/useStorageEvent';
import { getPermissions } from '@/utils/authority';
import { useState } from 'react';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState(getPermissions() ?? []);

  useStorageEvent('permissions', () => {
    setPermissions(getPermissions() ?? []);
  });

  return { permissions };
};
