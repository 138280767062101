import { EnvironmentService } from '@/services/environment';

export const redirectToAuthClient = () => {
  const { origin, pathname, search, hash } = window.location;

  const params = new URLSearchParams({
    // Base64-encoded to avoid conflicts with query params after redirect
    returnTo: btoa(`${pathname}${search}${hash}`),
    callbackUrl: `${origin}/auth/callback`,
  });
  const path = `/login?${params}`;

  EnvironmentService.getAuthClientUrl().then((authClientUrl) => {
    window.location.href = authClientUrl + path;
  });
};
